form{
  padding: 0 1rem;
}

.form-field__container{
  padding: 0 1rem;
  margin: 10px 0;
}

.form-field__container.label-container{
  padding: 0 1rem;
  margin-bottom: 0px;
}

.form-field__container.input-container{
  margin: 6px 0 16px;
}

.form-field__container.helper-text{
  margin: 4px 0;
  margin-top: -6px;
  margin-bottom: 0px;
}

.form-text-field{
  width: -webkit-fill-available;
  height: 50px;
  border-radius: 20px;
  padding: 8px;
  text-align: center;
  outline: none !important;
  font-size: 16px;
  max-width: 250px;
  border: 2px transparent solid;
  transition: all .2s;
}

.form-text-field:focus, .form-text-field:hover, .form-text-field:active, .form-text-field:focus-within, .form-text-field:focus-visible{
  border: #D66B6B 2px solid;
}


.form-text-field#otp{
}

input::placeholder{
  color: #676767;
}

.form-helper-text{
  font-size: 12px;
  color: #676767;
  margin-bottom: 0px !important;
}

.form-label{
  font-size: 16px;
  margin-bottom: 0;
}

.form-submit{
  width: 160px;

}

.form-submit.fetching{
  background: #a65050 !important;
  border-color: #a65050 !important;
}

.error-message {
  font-size: 11px;
  font-style: italic;
  color:#eb3b3b;
  min-height: 17px;
}
