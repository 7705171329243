.caret-container{
  position: absolute;
  top: .2rem;
  right: 1rem;
  transition: all .5;
  transform: rotate(0deg);
  transition-duration: .5s;
}

.caret-container {
    transform: rotate(0deg);
}

.caret-container.open {
    transform: rotate(-180deg);
}
