.App {
  text-align: center;
}

.header{
  padding: .5rem 0 1rem;
  font-size: 16px;
}

.content {
  background-color: #212121;
  color: #fff;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  height: 100%
}

.wrapper{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 800px){
  .wrapper{
    width: 700px;
    align-self: center;
  }
}

a{
  color: #fff;
  transition: all .31s;
}

a:hover{
  color: #D66B6B;
}

button.button-primary, button.button-primary:active, button.button-primary:focus, button.button-primary:focus-within, button.button-primary:visited {
  background: #D66B6B !important;
  border: none !important;
  border-radius: 20px;
  font-size: 12px;
  color: #fff;
  padding: 5px 20px;
  height: 35px;
  border-color: #D66B6B;
  transition: all .3s;
  cursor: pointer !important;

}

.button-primary:hover {
  background: #a65050 !important;
  border-color: #a65050 !important;
  cursor: pointer !important;
}

.content__vertical-center {
  margin-top: 20vh;
}

.content__vertical-top {
}

.app-link {
  color: #fff;
  text-decoration: none;
  font-size: 12px;
}

button, input[type="button"], input[type="submit"]{
  background: #D66B6B;
  border: none;
  border-radius: 20px;
  font-size: 12px;
  color: #fff;
  padding: 5px 20px;
  height: 35px;
}
