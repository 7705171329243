.job-wrapper{
  margin-bottom: 1rem;
}

.job-wrapper.open .card{
  border-radius: 0px 0px 20px 20px !important;
}

.job-wrapper.collapsed .card{
  border-radius: 0px !important;
}

.card{
    background: transparent !important;
    border: none;
    border-radius: 0;
}

.accordion-header-toggle__container, .job-title {
  position: relative;
  padding: 5px 1rem .5rem;
}

.accordion-header-toggle__container{
  cursor: pointer !important;
}

.accordion-header-toggle__container:hover {
  cursor: pointer !important;
}

button.custom-accordion-toggle  {
  width:100%;
  height: 100%;
  background-color: transparent;
  color: #fff;
  text-align: center;
  top: 0;
  right: 0;
  padding: 0;
  font-size: 14px;
}

.job-grid-container{
  padding: 0 5px;
}

.accordion-collapse.collapse.show{
  background: #2E2E2E !important;
  border-radius: 0 0 20px 20px !important;
}

.accordion-collapse{
  background: #2E2E2E !important;
}

.card-header{
  z-index: 3;
  border: none;
  background: #2E2E2E !important;
  position: relative;
  padding: .5rem 1rem .8rem;
}

.accordion-button {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1rem;
}

.text-left{
  text-align: left;
}

.text-right{
  text-align: right;
}

.status-container{
  font-size: 12px;
  display: flex;
  justify-content: center;
  margin-top: .5rem;
}

.row {
  padding: 15px 0px;
}

.row:not(:last-of-type) {
  border-bottom: 1px solid white;
}

.row.complete * {
    color: #676767;
}

.row *{
  font-size: 12px;
}

.row.active * {
    font-weight: 600;
}

.upcoming.row .col-4:last-of-type {
    color: #D66B6B;
}

.upcoming.row .col-4:first-of-type {
    color: #676767;
}

.text-notifications{
  text-transform: uppercase;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer !important;
  min-width: 26px !important;
  width: 26px;
  display: inline-block;
}

.text-notifications.off{
  color: #D66B6B;
}


.text-notifications:hover{
  cursor: pointer !important;
}

.completed-job-container {
    padding: 0.5rem 0 .8rem;
    background: #2E2E2E;
}

.job-date.status-container span {
    margin-right: 5px;
}

button.text-notifications {
  background-color: transparent;
  padding: 0;
  height: auto;
}