nav{
  min-height: 40px;
  padding: 2.2rem 1.5rem .5rem !important;
}


span.logo{
  font-weight: 600;
}

.authenticated-nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logout-button{
  background: #D66B6B;
  border: none;
  border-radius: 20px;
  font-size: 12px;
  color: #fff;
  padding: 5px 20px;
  width: 90px;
  height: 35px;
}
